@use "../util" as *;

.contact {
  padding: rem(128) rem(96);

  &__wrapper {
    // background: linear-gradient(135deg, #ffeded, #ffeedb);
    background: #ffeded;
    padding: rem(128) rem(80);
    border-radius: rem(20);
    text-align: center;
    @media (max-width: rem(850)) {
      border-radius: rem(0);
      padding: 0;
    }

    @media (max-width: rem(490)) {
      margin-top: rem(140);
    }

    &__heading {
      margin: 0 auto;
      margin-bottom: rem(48);
      font-size: 64px;
      line-height: 1.15;
      font-weight: 600;
      letter-spacing: -0.04em;
      max-width: rem(700);
      @media (max-width: rem(850)) {
        font-size: rem(40);
      }
      @media (max-width: rem(475)) {
        font-size: rem(36);
        max-width: 100%;
      }
    }

    &__email {
      // font-size: 40px;
      // line-height: 1.3;
      // font-weight: 600;
      // letter-spacing: -0.03em;
      // cursor: pointer;
      // background-color: var(--links-hover);
      // display: inline-block;
      // padding: rem(15) rem(30);
      // border-radius: rem(9);
      // border: 3px solid #ffeedb;

      // transition: background-color 0.1s ease;
      // @media (max-width: rem(850)) {
      //   font-size: rem(25);
      //   padding: rem(10) rem(20);
      // }

      // &:hover {
      //   color: var(--links-hover);
      //   background-color: #ffeedb;
      //   border: 3px solid var(--links-hover);
      // }

      &__link {
        color: #ffeded;
        font-size: 40px;
        line-height: 1.3;
        font-weight: 600;
        letter-spacing: -0.03em;
        cursor: pointer;
        background-color: var(--links-hover);
        display: inline-block;
        padding: rem(15) rem(30);
        border-radius: rem(9);
        border: 3px solid #ffeded;

        transition: background-color 0.1s ease;
        @media (max-width: rem(850)) {
          font-size: rem(25);
          padding: rem(10) rem(20);
        }

        &:hover {
          color: var(--links-hover);
          background-color: #ffeded;
          border: 3px solid var(--links-hover);
        }
      }

      // &__coffee {
      //   font-size: 40px;
      //   cursor: pointer;
      //   background-color: #ffeded;
      //   display: inline-block;
      //   padding: rem(15) rem(30);
      //   transition: background-color 0.1s ease;

      //   @media (max-width: rem(850)) {
      //     font-size: rem(25);
      //     padding: rem(10) rem(20);
      //   }

      // &:hover {
      // color: var(--links-hover);
      // background-color: #ffeded;
      // border: 3px solid var(--links-hover);
      // }
      // }
    }

    @media (max-width: rem(850)) {
      padding: rem(80) rem(40);
    }
  }

  @media (max-width: rem(960)) {
    padding: rem(112) rem(64);
  }
  @media (max-width: rem(475)) {
    padding: 0;
  }
  @media (max-width: rem(850)) {
    border-radius: rem(0);
    padding: 0;
  }
}
