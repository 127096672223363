@use "../util" as *;

.hero {
  &__heading {
    font-weight: 600;
    font-size: rem(64);
    line-height: 1.15;
    letter-spacing: -0.04em;
    padding: rem(128) rem(96);
    max-width: rem(1100);
    &--grey {
      color: var(--grey);
    }

    @media (max-width: rem(960)) {
      padding: rem(112) rem(64);
    }

    @media (max-width: rem(766)) {
      font-size: 40px;
    }
    @media (max-width: rem(475)) {
      padding: rem(64) rem(40);
    }
  }
}
