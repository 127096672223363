@use "../util" as *;
.work {
  padding: rem(128) rem(96);

  &__text {
    font-size: rem(18);
    line-height: 1.3;
    font-weight: 600;
    letter-spacing: -0.03em;
    margin-bottom: rem(32);
    &--grey {
      color: var(--grey);
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: rem(24);
    height: rem(1000);
    max-height: rem(1400);

    &__item {
      border-radius: 18px;
      // background-color: rgb(161, 193, 232);
      width: auto;
      height: auto;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
        transition: opacity 0.2s ease;
      }

      &__imagecontainer {
        height: 70%;
        width: 100%;
        // background: rgb(203, 196, 196);

        &__shoead {
          background: url("../../../images/shoe.jpg");
          // background-size: auto;
          background-size: contain;
          background-color: #cc061d;
          align-items: center;
          background-position: center;
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
        }

        &__htmlcanvas {
          // background: url("../../../images/html-canvas.png");
          // height: 100%;
          // width: 100%;
          // background-size: cover;
          // background-color: white;
          // align-items: center;
          // background-position: center;
          // background-repeat: no-repeat;
          height: 100%;
          width: 550%;
        }

        &__rps {
          background: url("../../../images/rock-papers-scissors.png");
          background-size: contain;
          background-color: #272944;
          background-position: center;
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
        }

        &__jumpinggame {
          background: url("../../../images/doodle.png");
          // background-size: auto;
          background-size: cover;
          // background-color: #cc061d;
          // background: url("https://github.com/anniiissshhhh/JumpingGame/blob/main/images/background.png");
          align-items: center;
          background-position: center;
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
        }
      }

      &__content {
        background-color: #f7f6f6;
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;
        padding: rem(24) rem(28) rem(24) rem(24);

        font-size: 20px;
        line-height: 1.3;
        font-weight: 600;
        letter-spacing: -0.03em;
        &__heading {
          color: black;
        }

        &__tech {
          margin-top: rem(4);
          // color: var(--grey);
          color: #868687;
        }
      }
    }
    @media (max-width: rem(840)) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(4, 1fr);
      gap: rem(16);
      height: rem(1500);
      max-height: rem(1900);
    }
  }

  @media (max-width: rem(960)) {
    padding: rem(112) rem(64);
  }
  @media (max-width: rem(475)) {
    padding: rem(64) rem(40);
  }
}
