@use "../util" as *;

.footer {
  padding: rem(128) rem(96) rem(64) rem(96);
  display: flex;
  justify-content: space-between;

  @media (max-width: rem(475)) {
    display: flex;
    flex-direction: row;
  }

  &__page {
    &__link {
      display: inline-block;
      color: #000;
      font-size: 20px;
      font-weight: 600;
      text-decoration: none;
      margin-right: rem(32);
      transition: color 0.2s ease;

      @media (max-width: rem(475)) {
        margin-bottom: 20px;
        width: 40%;
      }

      &:hover {
        color: var(--links-hover);
      }

      @media (max-width: rem(475)) {
        font-size: rem(16);
      }
    }

    @media (max-width: rem(475)) {
      display: flex;
      flex-direction: column;
    }
  }

  &__socials {
    &__link {
      display: inline-block;
      color: #000;
      font-size: 20px;
      font-weight: 600;
      text-decoration: none;
      margin-right: rem(32);
      transition: color 0.2s ease;

      @media (max-width: rem(475)) {
        margin-bottom: 20px;
        width: 50%;
      }

      &:hover {
        color: var(--links-hover);
      }

      @media (max-width: rem(475)) {
        font-size: rem(16);
      }
    }

    @media (max-width: rem(475)) {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: rem(1000)) {
    flex-direction: column;

    &__page {
      padding-bottom: rem(64);
    }
  }

  @media (max-width: rem(960)) {
    padding: rem(112) rem(64) rem(30) rem(64);
  }

  @media (max-width: rem(475)) {
    padding: rem(64) rem(40) rem(0) rem(40);
  }
}
