@use "../util" as *;

.about-me {
  padding: rem(128) rem(96);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: baseline;

  &__heading {
    width: 50%;
    font-size: 32px;
    line-height: 1.3;
    font-weight: 600;
    letter-spacing: -0.02em;
    @media (max-width: rem(766)) {
      width: 100%;
      font-size: rem(28);
    }
  }

  &__text {
    width: 50%;
    font-size: 28px;
    line-height: 1.5;
    color: rgb(49, 49, 49);
    @media (max-width: rem(766)) {
      width: 100%;
      font-size: rem(28);
    }
    @media (max-width: rem(475)) {
      font-size: rem(23);
    }
  }

  @media (max-width: rem(960)) {
    padding: rem(112) rem(64);
  }

  @media (max-width: rem(766)) {
    flex-direction: column;
  }
  @media (max-width: rem(475)) {
    padding: rem(64) rem(40);
  }
}
