:root {
  --font-body: "Inter", sans-serif;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --links-hover: hsl(0, 0%, 16%);
  --grey: hsl(0, 0%, 46%);
  --nav-links: hsl(0, 0%, 32%);
  --nav-links-hover: hsl(0, 0%, 98%);
  --border: #f0f0f0;
  --grey-hover: rgb(81, 80, 80);
}

html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-body);
  display: grid;
  place-content: center;
  width: 100vw;
  overflow-x: hidden;
  /* Hide scrollbars */
}

.containerop {
  max-width: rem(1536);
}

.containercentermain {
  max-width: 1536px;
  display: grid;
  place-content: center;
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  list-style-type: none;
}

.container {
  padding: 0 1.5rem;
  max-width: 69.375rem;
  margin: 0 auto;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
    transform: translate(0, 0);
  }
}

.hero__heading {
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.15;
  letter-spacing: -0.04em;
  padding: 8rem 6rem;
  max-width: 68.75rem;
}
.hero__heading--grey {
  color: var(--grey);
}
@media (max-width: 60rem) {
  .hero__heading {
    padding: 7rem 4rem;
  }
}
@media (max-width: 47.875rem) {
  .hero__heading {
    font-size: 40px;
  }
}
@media (max-width: 29.6875rem) {
  .hero__heading {
    padding: 4rem 2.5rem;
  }
}

.navbar {
  display: flex;
  max-width: 100rem;
  justify-content: space-between;
  padding: 0 6rem;
  height: 4.75rem;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: 998;
}
.navbar__logo {
  font-weight: bold;
  font-size: 2.1875rem;
  cursor: pointer;
  transform: rotate(40deg);
  transition: transform 0.4s ease;
  color: black;
}
.navbar__logo:hover {
  transform: rotate(-360deg);
  color: ver(--grey-hover);
}
.navbar__links {
  display: flex;
}
.navbar__links__link {
  color: var(--nav-links);
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0.375rem;
  padding: 0.625rem 0.875rem;
  transition: background-color 0.1s;
  transition-timing-function: ease-out;
}
@media (max-width: 47.8125rem) {
  .navbar__links__link {
    opacity: 0;
    pointer-events: none;
  }
}
.navbar__links__link:hover {
  background-color: var(--nav-links-hover);
  color: var(--grey-hover);
  border-radius: 0.3125rem;
}
.navbar__hamburger {
  display: none;
}
@media (max-width: 47.8125rem) {
  .navbar__hamburger {
    display: inline-block;
    position: absolute;
    right: 37px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
  }
}
@media (max-width: 47.8125rem) and (max-width: 400) {
  .navbar__hamburger {
    right: 30px;
  }
}
@media (max-width: 47.8125rem) {
  .navbar__hamburger:before {
    content: "";
    position: absolute;
    width: 28px;
    height: 3px;
    background-color: black;
    transition: 0.2s;
    transform: translateY(-10px);
    box-shadow: 0 10px 0 black;
  }
}
@media (max-width: 47.8125rem) {
  .navbar__hamburger:after {
    content: "";
    position: absolute;
    width: 28px;
    height: 3px;
    background-color: black;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media (max-width: 47.8125rem) {
  .navbar__hamburger__active:before {
    transform: translateY(0px) rotate(45deg);
    box-shadow: 0 0px 0 black;
  }
  .navbar__hamburger__active:after {
    transform: translateY(0px) rotate(-45deg);
  }
}
@media (max-width: 60rem) {
  .navbar {
    padding: 0 4rem;
  }
}
@media (max-width: 31.25rem) {
  .navbar {
    padding: 0 2.9375rem;
  }
}

.work {
  padding: 8rem 6rem;
}
.work__text {
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: -0.03em;
  margin-bottom: 2rem;
}
.work__text--grey {
  color: var(--grey);
}
.work__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1.5rem;
  height: 62.5rem;
  max-height: 87.5rem;
}
.work__wrapper__item {
  border-radius: 18px;
  width: auto;
  height: auto;
  overflow: hidden;
  cursor: pointer;
}
.work__wrapper__item:hover {
  opacity: 0.9;
  transition: opacity 0.2s ease;
}
.work__wrapper__item__imagecontainer {
  height: 70%;
  width: 100%;
}
.work__wrapper__item__imagecontainer__shoead {
  background: url("../../../images/shoe.jpg");
  background-size: contain;
  background-color: #cc061d;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.work__wrapper__item__imagecontainer__htmlcanvas {
  height: 100%;
  width: 550%;
}
.work__wrapper__item__imagecontainer__rps {
  background: url("../../../images/rock-papers-scissors.png");
  background-size: contain;
  background-color: #272944;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.work__wrapper__item__imagecontainer__jumpinggame {
  background: url("../../../images/doodle.png");
  background-size: cover;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.work__wrapper__item__content {
  background-color: #f7f6f6;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  padding: 1.5rem 1.75rem 1.5rem 1.5rem;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: -0.03em;
}
.work__wrapper__item__content__heading {
  color: black;
}
.work__wrapper__item__content__tech {
  margin-top: 0.25rem;
  color: #868687;
}
@media (max-width: 52.5rem) {
  .work__wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 1rem;
    height: 93.75rem;
    max-height: 118.75rem;
  }
}
@media (max-width: 60rem) {
  .work {
    padding: 7rem 4rem;
  }
}
@media (max-width: 29.6875rem) {
  .work {
    padding: 4rem 2.5rem;
  }
}

.about-me {
  padding: 8rem 6rem;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: baseline;
}
.about-me__heading {
  width: 50%;
  font-size: 32px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: -0.02em;
}
@media (max-width: 47.875rem) {
  .about-me__heading {
    width: 100%;
    font-size: 1.75rem;
  }
}
.about-me__text {
  width: 50%;
  font-size: 28px;
  line-height: 1.5;
  color: #313131;
}
@media (max-width: 47.875rem) {
  .about-me__text {
    width: 100%;
    font-size: 1.75rem;
  }
}
@media (max-width: 29.6875rem) {
  .about-me__text {
    font-size: 1.4375rem;
  }
}
@media (max-width: 60rem) {
  .about-me {
    padding: 7rem 4rem;
  }
}
@media (max-width: 47.875rem) {
  .about-me {
    flex-direction: column;
  }
}
@media (max-width: 29.6875rem) {
  .about-me {
    padding: 4rem 2.5rem;
  }
}

.skills {
  padding: 8rem 6rem;
  display: grid;
  gap: 5rem;
  width: 100%;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 30.625rem) {
  .skills {
    grid-template-columns: 1fr;
  }
}
.skills__wrapper__heading {
  font-size: 32px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: 4rem;
}
.skills__wrapper__list {
  width: 100%;
}
.skills__wrapper__list__item {
  display: block;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: -0.03em;
  display: inline-block;
  width: 100%;
  padding-bottom: 1.5rem;
  text-align: start;
}
.skills__wrapper__list__hr {
  width: auto;
  margin: 0;
  padding: 0;
  border-top: 0.05px solid var(--border);
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin-bottom: 1.5rem;
}
@media (max-width: 30.625rem) {
  .skills__wrapper {
    height: 250px;
  }
}
@media (max-width: 60rem) {
  .skills {
    padding: 7rem 4rem;
  }
}
@media (max-width: 29.6875rem) {
  .skills {
    padding: 4rem 2.5rem;
  }
}

.contact {
  padding: 8rem 6rem;
}
.contact__wrapper {
  background: #ffeded;
  padding: 8rem 5rem;
  border-radius: 1.25rem;
  text-align: center;
}
@media (max-width: 53.125rem) {
  .contact__wrapper {
    border-radius: 0rem;
    padding: 0;
  }
}
@media (max-width: 30.625rem) {
  .contact__wrapper {
    margin-top: 8.75rem;
  }
}
.contact__wrapper__heading {
  margin: 0 auto;
  margin-bottom: 3rem;
  font-size: 64px;
  line-height: 1.15;
  font-weight: 600;
  letter-spacing: -0.04em;
  max-width: 43.75rem;
}
@media (max-width: 53.125rem) {
  .contact__wrapper__heading {
    font-size: 2.5rem;
  }
}
@media (max-width: 29.6875rem) {
  .contact__wrapper__heading {
    font-size: 2.25rem;
    max-width: 100%;
  }
}
.contact__wrapper__email__link {
  color: #ffeded;
  font-size: 40px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: -0.03em;
  cursor: pointer;
  background-color: var(--links-hover);
  display: inline-block;
  padding: 0.9375rem 1.875rem;
  border-radius: 0.5625rem;
  border: 3px solid #ffeded;
  transition: background-color 0.1s ease;
}
@media (max-width: 53.125rem) {
  .contact__wrapper__email__link {
    font-size: 1.5625rem;
    padding: 0.625rem 1.25rem;
  }
}
.contact__wrapper__email__link:hover {
  color: var(--links-hover);
  background-color: #ffeded;
  border: 3px solid var(--links-hover);
}
@media (max-width: 53.125rem) {
  .contact__wrapper {
    padding: 5rem 2.5rem;
  }
}
@media (max-width: 60rem) {
  .contact {
    padding: 7rem 4rem;
  }
}
@media (max-width: 29.6875rem) {
  .contact {
    padding: 0;
  }
}
@media (max-width: 53.125rem) {
  .contact {
    border-radius: 0rem;
    padding: 0;
  }
}

.footer {
  padding: 8rem 6rem 4rem 6rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 29.6875rem) {
  .footer {
    display: flex;
    flex-direction: row;
  }
}
.footer__page__link {
  display: inline-block;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  margin-right: 2rem;
  transition: color 0.2s ease;
}
@media (max-width: 29.6875rem) {
  .footer__page__link {
    margin-bottom: 20px;
    width: 40%;
  }
}
.footer__page__link:hover {
  color: var(--links-hover);
}
@media (max-width: 29.6875rem) {
  .footer__page__link {
    font-size: 1rem;
  }
}
@media (max-width: 29.6875rem) {
  .footer__page {
    display: flex;
    flex-direction: column;
  }
}
.footer__socials__link {
  display: inline-block;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  margin-right: 2rem;
  transition: color 0.2s ease;
}
@media (max-width: 29.6875rem) {
  .footer__socials__link {
    margin-bottom: 20px;
    width: 50%;
  }
}
.footer__socials__link:hover {
  color: var(--links-hover);
}
@media (max-width: 29.6875rem) {
  .footer__socials__link {
    font-size: 1rem;
  }
}
@media (max-width: 29.6875rem) {
  .footer__socials {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 62.5rem) {
  .footer {
    flex-direction: column;
  }
  .footer__page {
    padding-bottom: 4rem;
  }
}
@media (max-width: 60rem) {
  .footer {
    padding: 7rem 4rem 1.875rem 4rem;
  }
}
@media (max-width: 29.6875rem) {
  .footer {
    padding: 4rem 2.5rem 0rem 2.5rem;
  }
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
  background-color: black;
  height: 100vh;
  width: 60%;
  text-transform: uppercase;
}
.navigation__active {
  transform: translateX(0);
}
.navigation__active__container {
  display: flex;
  align-items: center;
  height: 76px;
}
.navigation__active__container__logo {
  transform: rotate(40deg);
  transition: transform 0.4s ease;
  color: white;
  font-weight: bold;
  font-size: 35px;
  cursor: pointer;
}
.navigation__active__container__logo:hover {
  transform: rotate(-360deg);
  color: ver(--grey-hover);
}
.navigation__active__wrapper {
  padding-top: 70px;
  position: sticky;
  top: 76px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 300px;
}
.navigation__active__wrapper__link {
  display: inline-block;
  width: 100%;
  color: white;
  font-size: 1.125rem;
  font-weight: 600;
  transition: background-color 0.2s;
  transition-timing-function: ease-in-out;
}
.navigation__active__wrapper__link:hover {
  color: #bcbcbc;
  border-radius: 0.3125rem;
}
@media (max-width: 60rem) {
  .navigation {
    padding: 0 4rem;
  }
}
@media (max-width: 31.25rem) {
  .navigation {
    padding: 0 2.5rem;
  }
}

.display_none {
  display: none;
}