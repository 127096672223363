@use "../util" as *;

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  list-style-type: none;
}
