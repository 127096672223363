@use "../util" as *;

.skills {
  padding: rem(128) rem(96);
  display: grid;
  gap: rem(80);
  width: 100%;
  grid-template-columns: 1fr 1fr;
  @media (max-width: rem(490)) {
    grid-template-columns: 1fr;
  }

  &__wrapper {
    &__heading {
      font-size: 32px;
      line-height: 1.3;
      font-weight: 600;
      letter-spacing: -0.02em;
      margin-bottom: rem(64);
    }

    &__list {
      width: 100%;

      &__item {
        display: block;
        font-size: 20px;
        line-height: 1.3;
        font-weight: 600;
        letter-spacing: -0.03em;
        display: inline-block;
        width: 100%;
        padding-bottom: rem(24);
        text-align: start;
      }

      &__hr {
        width: auto;
        margin: 0;
        padding: 0;
        border-top: 0.05px solid var(--border);
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        margin-bottom: rem(24);
      }
    }

    @media (max-width: rem(960)) {
    }
    @media (max-width: rem(490)) {
      height: 250px;
      // padding-bottom: 70px;
    }
  }

  @media (max-width: rem(960)) {
    padding: rem(112) rem(64);
  }
  @media (max-width: rem(475)) {
    padding: rem(64) rem(40);
  }
}
