@use "../util" as *;
.navbar {
  display: flex;
  max-width: rem(1600);
  justify-content: space-between;
  padding: 0 rem(96);
  height: rem(76);
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: 998;

  &__logo {
    font-weight: bold;
    font-size: rem(35);
    cursor: pointer;
    transform: rotate(40deg);
    transition: transform 0.4s ease;
    color: black;

    &:hover {
      transform: rotate(-360deg);
      color: ver(--grey-hover);
    }
  }

  &__links {
    display: flex;
    &__link {
      color: var(--nav-links);
      font-size: rem(18);
      font-weight: 600;
      margin: 0 rem(6);
      padding: rem(10) rem(14);
      transition: background-color 0.1s;
      transition-timing-function: ease-out;

      @media (max-width: rem(765)) {
        opacity: 0;
        pointer-events: none;
      }

      &:hover {
        background-color: var(--nav-links-hover);
        color: var(--grey-hover);
        border-radius: rem(5);
      }
    }
  }

  &__hamburger {
    display: none;

    @media (max-width: rem(765)) {
      display: inline-block;
      position: absolute;
      right: 37px;
      width: 50px;

      height: 50px;
      // background-color: #37a6ff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;

      @media (max-width: 400) {
        right: 30px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 28px;
        height: 3px;
        background-color: black;
        transition: 0.2s;
        transform: translateY(-10px);
        box-shadow: 0 10px 0 black;
      }
      &:after {
        content: "";
        position: absolute;
        width: 28px;
        height: 3px;
        background-color: black;
        transition: 0.2s;
        transform: translateY(10px);
      }

      &__active {
        // background-color: #f7226a;

        &:before {
          transform: translateY(0px) rotate(45deg);
          box-shadow: 0 0px 0 black;
        }

        &:after {
          transform: translateY(0px) rotate(-45deg);
        }
      }
    }
  }

  @media (max-width: rem(960)) {
    padding: 0 rem(64);
  }

  @media (max-width: rem(500)) {
    padding: 0 rem(47);
  }
}
