html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-body);
  display: grid;
  place-content: center;
  width: 100vw;
  overflow-x: hidden; /* Hide scrollbars */
  // font-family: poppins;
  // cursor: url("../../../images/favicon.gif"), auto;
}

.containerop {
  max-width: rem(1536);
}

.containercentermain {
  max-width: 1536px;
  display: grid;
  place-content: center;
}

// .cursor {
//   width: 30px;
//   height: 30px;
//   border: 3px solid black;
//   border-radius: 50%;
//   position: absolute;
//   // transition-duration: 100ms;
//   transition-timing-function: ease-out;
//   animation: cursorAnim1 0.5s infinite alternate;
//   z-index: 99999;
// }

// .cursor::after {
//   box-sizing: content-box;
//   content: "";
//   width: 30px;
//   height: 30px;
//   position: absolute;
//   border: 9px solid gray;
//   border-radius: 50%;
//   opacity: 0.5;
//   top: -11px;
//   left: -11px;
//   // animation: cursorAnim2 0.5s infinite alternate;
// }

// @keyframes cursorAnim1 {
//   from {
//     transform: scale(1);
//   }
//   to {
//     transform: scale(0.7);
//   }
// }
