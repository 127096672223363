@use "../util" as *;

// .navigation {
//   position: absolute;
//   &__active {
//     z-index: 999;
//     background-color: black;
//     width: 60%;
//     height: 663.5vh;

//     &__logo {
//       position: sticky;
//       top: 0;
//       display: flex;
//       align-items: center;
//       height: 76px;
//       color: white;

//       //aa
//       font-weight: bold;
//       font-size: 35px;
//       cursor: pointer;
//     }
//     &__wrapper {
//       // padding-top: 70px;
//       position: sticky;
//       top: 76px;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-around;
//       height: 200px;

//       // border: 1px solid white;
//       &__link {
//         display: inline-block;
//         width: 100%;
//         color: white;
//         font-size: rem(18);
//         font-weight: 600;
//       }
//     }
//   }

//   @media (max-width: rem(960)) {
//     padding: 0 rem(64);
//   }

//   @media (max-width: rem(500)) {
//     padding: 0 rem(40);
//   }
// }

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
  background-color: black;
  height: 100vh;
  width: 60%;
  text-transform: uppercase;

  &__active {
    transform: translateX(0);
    &__container {
      display: flex;
      align-items: center;
      height: 76px;

      &__logo {
        transform: rotate(40deg);
        transition: transform 0.4s ease;
        // color: black;

        &:hover {
          transform: rotate(-360deg);
          color: ver(--grey-hover);
        }

        //
        color: white;
        //aa
        font-weight: bold;
        font-size: 35px;
        cursor: pointer;
        // border: 1px solid white;
      }
    }

    &__wrapper {
      padding-top: 70px;
      position: sticky;
      top: 76px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 300px;
      // border: 1px solid white;
      &__link {
        display: inline-block;
        width: 100%;
        color: white;
        font-size: rem(18);
        font-weight: 600;
        transition: background-color 0.2s;
        transition-timing-function: ease-in-out;

        &:hover {
          color: rgb(188, 188, 188);
          border-radius: rem(5);
        }
      }
    }
  }

  @media (max-width: rem(960)) {
    padding: 0 rem(64);
  }

  @media (max-width: rem(500)) {
    padding: 0 rem(40);
  }

  // media (min-width: rem(960)) {
  //   display: none;
  // }

  // @media (min-width: rem(500)) {
  //   display: none;
  // }
}

.display_none {
  display: none;
}
