:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --links-hover: hsl(0, 0%, 16%);

  --grey: hsl(0, 0%, 46%);
  --nav-links: hsl(0, 0%, 32%);
  --nav-links-hover: hsl(0, 0%, 98%);
  --border: #f0f0f0;
  --grey-hover: rgb(81, 80, 80);
}
